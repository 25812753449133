import React, {useState} from 'react'
import Card from './Card'



const Page = () => {
    

    function importAll(r) {
        // let images = {};
        let images = []
        r.keys().map((item, index) => { 
          //images[item.replace('./', '')] = r(item); 
          images.push(r(item))
        })
        
        return images;
    }
      
    const images = importAll(require.context('../public/projects', false, /\.(png|jpe?g|svg|webp)$/));


    const [index, setIndex] = useState(0)
    const [initialClick, setInitialClick] = useState(false)

    const onClickBack = () => {
      if(index <= 0) {
        
        setIndex(images.length - 1)
      }
      else {
        setIndex(prevCount => prevCount - 1)
      }
      
    }

    const onClickForward = () => {
      if(index === images.length - 1) {
        setIndex(0)
      }
      else {
        setIndex(prevCount => prevCount + 1)
      }
      
    }

    const handleClick = (e) => {
      const mid = window.innerWidth/ 2
      if ( e.clientX < mid) {
        onClickBack()
      }
      else {
        onClickForward()
      }
    }

    const [showEmail, setShowEmail] = useState(false)

    const handleShowEmail = () => {
      setShowEmail(!showEmail)
    }


    return (
      <div> 
        <div className = "title"><img src={"/logo/logo.webp"}  alt="" className= "buro-img"/>  </div>
        <div className = "box"> 
          {/* <Card index= {index} initialClick= {initialClick}/> */}

          
          <div className = "image-box" >
            <img src={images[index]} alt=""  className = "image" onClick = {handleClick}/>  
          </div>

          <div className = "email-box">
            {!showEmail ? <div className = "contact-div" onClick = {handleShowEmail}><img src={"/text/email.jpeg"}  alt="" className= "email-img"/> </div> 
              : <a href = "mailto: gabriel.hafner1@gmail.com" className= "email">gabriel.hafner1@gmail.com </a>
            }
          </div>
          
        </div>
      </div>
    );
  };
  


export default Page